import React, { lazy } from 'react'

const OutOfStateAssignmentListing = lazy(() => 
  import('./OutOfStateAssignment/OutOfStateAssignmentListing').then(module => ({
    default: module.OutOfStateAssignmentListing
  }))
)

const OutOfStateAssignmentForm = lazy(() => 
  import('./OutOfStateAssignment/OutOfStateAssignmentForm').then(module => ({
    default: module.OutOfStateAssignmentForm
  }))
)

const LeaveApplicationListing = lazy(() => 
  import('./LeaveApplication/LeaveApplicationListing').then(module => ({
    default: module.LeaveApplicationListing
  }))
)

const LeaveApplicationForm = lazy(() => 
  import('./LeaveApplication/LeaveApplicationForm').then(module => ({
    default: module.LeaveApplicationForm
  }))
)

const LeaveRecordDetail = lazy(() => 
  import('./LeaveApplication/LeaveRecordDetail').then(module => ({
    default: module.LeaveRecordDetail
  }))
)

const LeaveTaken = lazy(() => 
  import('./LeaveTaken/LeaveTaken').then(module => ({
    default: module.LeaveTaken
  }))
)

const LeaveTakenUpload = lazy(() => 
  import('./LeaveTaken/LeaveTakenUpload').then(module => ({
    default: module.LeaveTakenUpload
  }))
)

const LeaveAdjustment = lazy(() => 
  import('./LeaveAdjustment/LeaveAdjustment').then(module => ({
    default: module.LeaveAdjustment
  }))
)

const CreateLeaveAdjustment = lazy(() => 
  import('./LeaveAdjustment/CreateLeaveAdjustment').then(module => ({
    default: module.CreateLeaveAdjustment
  }))
)

const CompulsoryLeave = lazy(() => 
  import('./CompulsoryLeave/CompulsoryLeave').then(module => ({
    default: module.CompulsoryLeave
  }))
)

const CompulsoryLeaveForm = lazy(() => 
  import('./CompulsoryLeave/CompulsoryLeaveForm').then(module => ({
    default: module.CompulsoryLeaveForm
  }))
)

const BlockLeave = lazy(() => 
  import('./BlockLeave/BlockLeave').then(module => ({
    default: module.BlockLeave
  }))
)

const BlockLeaveForm = lazy(() => 
  import('./BlockLeave/BlockLeaveForm').then(module => ({
    default: module.BlockLeaveForm
  }))
)

const LeavePostingPeriodListing = lazy(() => 
  import('./PayDedcuctionPosting/LeavePostingPeriodListing').then(module => ({
    default: module.LeavePostingPeriodListing
  }))
)

const LeavePosting = lazy(() => 
  import('./PayDedcuctionPosting/LeavePosting').then(module => ({
    default: module.LeavePosting
  }))
)

const YearEndCarryForward = lazy(() => 
  import('./YearEndCarryForward/YearEndCarryForward').then(module => ({
    default: module.YearEndCarryForward
  }))
)

const OffboardingEncashmentPeriodListing = lazy(() => 
  import('./OffboardingEncashment/OffboardingEncashmentPeriodListing').then(module => ({
    default: module.OffboardingEncashmentPeriodListing
  }))
)

const OffboardingEncashment = lazy(() => 
  import('./OffboardingEncashment/OffboardingEncashment').then(module => ({
    default: module.OffboardingEncashment
  }))
)

const LeaveCarryForwardEncashment = lazy(() => 
  import('./LeaveCarryForwardEncashment/LeaveCarryForwardEncashment').then(module => ({
    default: module.LeaveCarryForwardEncashment
  }))
)

const LeaveEntitlementEmployeeListing = lazy(() => 
  import('./LeaveEntitlement/LeaveEntitlementEmployeeListing').then(module => ({
    default: module.LeaveEntitlementEmployeeListing
  }))
)

const LeaveEntitlementByEmployee = lazy(() => 
  import('./LeaveEntitlement/LeaveEntitlementByEmployee').then(module => ({
    default: module.LeaveEntitlementByEmployee
  }))
)

const ReplacementLeaveTable = lazy(() => 
  import('./LeaveEntitlement/ReplacementLeaveTable').then(module => ({
    default: module.ReplacementLeaveTable
  }))
)

const LeaveEntitlementByLeaveType = lazy(() => 
  import('./LeaveEntitlement/LeaveEntitlementByLeaveType').then(module => ({
    default: module.LeaveEntitlementByLeaveType
  }))
)
const LeaveApplicationCalendar = lazy(() =>
  import('./LeaveApplicationCalendar').then(module => ({
    default: module.LeaveApplicationCalendar,
  }))
)

const LeaveManagementRoutes = [
  {
    props: { exact: true, path: '/OutOfStateAssignment/OutOfStateAssignmentListing' },
    component: <OutOfStateAssignmentListing />,
  },
  {
    props: { exact: true, path: '/OutOfStateAssignment/OutOfStateAssignmentForm' },
    component: <OutOfStateAssignmentForm />,
  },
  {
    props: { exact: true, path: '/OutOfStateAssignment/OutOfStateAssignmentForm' },
    component: <OutOfStateAssignmentForm />,
  },
  {
    props: { exact: true, path: '/LeaveApplication/LeaveApplicationListing' },
    component: <LeaveApplicationListing />,
  },
  {
    props: { exact: true, path: '/LeaveApplication/LeaveApplicationForm/New' },
    component: <LeaveApplicationForm mode='new' />,
  },
  {
    props: { exact: true, path: '/LeaveApplication/LeaveApplicationForm/Edit' },
    component: <LeaveApplicationForm mode='edit' />,
  },
  {
    props: { exact: true, path: '/LeaveApplication/LeaveRecordDetail' },
    component: <LeaveRecordDetail />,
  },
  {
    props: { exact: true, path: '/LeaveTaken/LeaveTaken' },
    component: <LeaveTaken />,
  },
  {
    props: { exact: true, path: '/LeaveTaken/LeaveTakenUpload' },
    component: <LeaveTakenUpload />,
  },
  {
    props: { exact: true, path: '/LeaveAdjustment/LeaveAdjustment' },
    component: <LeaveAdjustment />,
  },
  {
    props: { exact: true, path: '/LeaveAdjustment/CreateLeaveAdjustment' },
    component: <CreateLeaveAdjustment />,
  },
  {
    props: { exact: true, path: '/CompulsoryLeave/CompulsoryLeave' },
    component: <CompulsoryLeave />,
  },
  {
    props: { exact: true, path: '/CompulsoryLeave/CompulsoryLeaveForm' },
    component: <CompulsoryLeaveForm />,
  },
  {
    props: { exact: true, path: '/BlockLeave/BlockLeave' },
    component: <BlockLeave />,
  },
  {
    props: { exact: true, path: '/BlockLeave/BlockLeaveForm' },
    component: <BlockLeaveForm />,
  },
  {
    props: { exact: true, path: '/PayDedcuctionPosting/LeavePostingPeriodListing' },
    component: <LeavePostingPeriodListing />,
  },
  {
    props: { exact: true, path: '/PayDedcuctionPosting/LeavePosting' },
    component: <LeavePosting />,
  },
  {
    props: { exact: true, path: '/YearEndCarryForward/YearEndCarryForward' },
    component: <YearEndCarryForward />,
  },
  {
    props: { exact: true, path: '/OffboardingEncashment/OffboardingEncashmentPeriodListing' },
    component: <OffboardingEncashmentPeriodListing />,
  },
  {
    props: { exact: true, path: '/OffboardingEncashment/OffboardingEncashment' },
    component: <OffboardingEncashment />,
  },
  {
    props: { exact: true, path: '/LeaveCarryForwardEncashment/LeaveCarryForwardEncashment' },
    component: <LeaveCarryForwardEncashment />,
  },
  {
    props: { exact: true, path: '/LeaveEntitlement/LeaveEntitlementEmployeeListing' },
    component: <LeaveEntitlementEmployeeListing />,
  },
  {
    props: { exact: true, path: '/LeaveEntitlement/LeaveEntitlementByEmployee' },
    component: <LeaveEntitlementByEmployee />,
  },
  {
    props: { exact: true, path: '/LeaveEntitlement/ReplacementLeaveTable' },
    component: <ReplacementLeaveTable />,
  },
  {
    props: { exact: true, path: '/LeaveEntitlement/LeaveEntitlementByLeaveType' },
    component: <LeaveEntitlementByLeaveType />,
  },
  {
    props: { exact: true, path: '/LeaveApplicationCalendar' },
    component: <LeaveApplicationCalendar />,
  },
]

export default LeaveManagementRoutes