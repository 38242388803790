import React, { lazy } from 'react'

const LeaveManagementListing = lazy(() =>
  import('./LeaveManagement/LeaveManagementListing').then(module => ({
    default: module.LeaveManagementListing,
  }))
)

const JobGradePolicyMatrixForm = lazy(() =>
  import(
    './LeaveManagement/CompanySetting/JobGradePolicyMatrix/JobGradePolicyMatrixForm'
  ).then(module => ({
    default: module.JobGradePolicyMatrixForm,
  }))
)

const LeaveCompanySetting = lazy(() =>
  import('./LeaveManagement/CompanySetting/LeaveCompanySetting').then(
    module => ({
      default: module.LeaveCompanySetting,
    })
  )
)

const JobGradePolicyMatrixListing = lazy(() =>
  import(
    './LeaveManagement/CompanySetting/JobGradePolicyMatrix/JobGradePolicyMatrixListing'
  ).then(module => ({
    default: module.JobGradePolicyMatrixListing,
  }))
)

const LeaveCutOffDate = lazy(() =>
  import('./LeaveManagement/CompanySetting/LeaveSetting/LeaveCutOffDate').then(
    module => ({
      default: module.LeaveCutOffDate,
    })
  )
)

const CompulsoryLeaveDeduction = lazy(() =>
  import(
    './LeaveManagement/CompanySetting/CumpulsoryLeaveDeduction/CompulsoryLeaveDeduction'
  ).then(module => ({
    default: module.CompulsoryLeaveDeduction,
  }))
)

const ApprovalPolicyAssignment = lazy(() =>
  import(
    './LeaveManagement/CompanySetting/LeaveApprovalPolicyAssignment/ApprovalPolicyAssignment'
  ).then(module => ({
    default: module.ApprovalPolicyAssignment,
  }))
)

const HomeRoutes = [
  {
    props: { exact: true, path: '/LeaveManagementListing' },
    component: <LeaveManagementListing />,
  },
  {
    props: {
      exact: true,
      path: '/LeaveManagement/CompanySetting/LeaveCompanySetting',
    },
    component: <LeaveCompanySetting />,
  },
  {
    props: {
      exact: true,
      path:
        '/LeaveManagement/CompanySetting/JobGradePolicyMatrix/JobGradePolicyMatrixListing',
    },
    component: <JobGradePolicyMatrixListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/LeaveManagement/CompanySetting/JobGradePolicyMatrix/JobGradePolicyMatrixForm',
    },
    component: <JobGradePolicyMatrixForm />,
  },
  {
    props: {
      exact: true,
      path: '/LeaveManagement/CompanySetting/LeaveSetting/LeaveCutOffDate',
    },
    component: <LeaveCutOffDate />,
  },
  {
    props: {
      exact: true,
      path:
        '/LeaveManagement/CompanySetting/CumpulsoryLeaveDeduction/CompulsoryLeaveDeduction',
    },
    component: <CompulsoryLeaveDeduction />,
  },
  {
    props: {
      exact: true,
      path:
        '/LeaveManagement/CompanySetting/LeaveApprovalPolicyAssignment/ApprovalPolicyAssignment',
    },
    component: <ApprovalPolicyAssignment />,
  },
]

export default HomeRoutes
